import React from "react";

import style from "./index.module.styl";

import ReactMarkdown from "react-markdown";

import C from "classnames";

interface IMarkdownProps {
  readonly className?: string;
  readonly source: string;
}

export default function Markdown({ className, source }: IMarkdownProps) {
  return (
    <div className={C(style.markdown, className)}>
      <ReactMarkdown source={source} />
    </div>
  );
}
