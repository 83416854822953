import React, { useMemo, useEffect } from "react";
import { graphql } from "gatsby";
import page from "..";
import GeneralLayout from "../../layouts/general";
import CenterColumn from "../../centerColumn";
import { IModel } from "../../../../../shared/src/models/model";
import Header from "../../header";
import { useGlobalStore } from "../../../store";
import { ROUTES } from "../../../common/routes.js";
import style from "./index.module.styl";
import Img from "gatsby-image";
import { IGatsbyPageProps } from "../../../common/gatsby";
import { ICollection } from "../../../../../shared/src/models/collection";
import { IInstallationTypeMapping } from "shared/lib/models/installationTypeMapping";
import Markdown from "../../markdown";
import SEO from "../../../components/seo";
import FilteredGrid, {
  IFilterGroup,
  IFilter,
  IFilterItems,
  FilterType,
} from "../../FilteredGrid";
import { ITherapy } from "shared/lib/models/therapy";

type ICollectionPageCollection = Omit<ICollection, "models"> & {
  readonly models: IModel[];
};

interface ICollectionProps
  extends IGatsbyPageProps<{
    readonly strapiCollections: ICollectionPageCollection;
    readonly allStrapiModels: {
      readonly nodes: IModel[];
    };
    readonly allStrapiInstallationTypeMappings: {
      readonly nodes: IInstallationTypeMapping[];
    };
  }> {
  readonly path: string;
}

const Collection = (props: ICollectionProps) => {
  const {
    name,
    image,
    description,
    descriptionFr,
    title,
    titleFr,
  } = props.data.strapiCollections;
  const collectionModels = props.data.allStrapiModels.nodes;
  const collection = props.data.strapiCollections;
  const installationsTypeMappings =
    props.data.allStrapiInstallationTypeMappings;
  const {
    makeLink,
    i18n,
    breadcrumbs,
    therapyHelpers: { getTherapyLabel },
  } = useGlobalStore();
  const localizedTitle = i18n.tObj(title, titleFr);
  const localizedDescription = i18n.tObj(description, descriptionFr);

  const mappedFilters = (therapies: ITherapy[], installationTypes: any) => {
    const therapyFilters: IFilter[] = therapies.map((therapy: ITherapy) => {
      return {
        id: therapy.id.toString(),
        filterType: FilterType.Therapy,
        checked: false,
        text: () => getTherapyLabel(therapy),
      };
    });
    const installationTypeFilters: IFilter[] = installationTypes.map(
      (installationType: any) => {
        return {
          id: (
            installationType.installation_type_mapping ?? installationType.id
          ).toString(),
          filterType: FilterType.InstallationType,
          checked: false,
          type: installationType.type,
          text: (i18n: any) =>
            i18n.tObj(installationType.title, installationType.title_fr),
        };
      },
    );
    return [...therapyFilters, ...installationTypeFilters];
  };

  const filters = mappedFilters(
    collection.therapies,
    installationsTypeMappings.nodes,
  );

  const gridItems = useMemo<IFilterItems[]>(() => {
    return collectionModels.map(
      ({ name, image, tubPrice, therapies, installationType }) => {
        return {
          name,
          href: makeLink(ROUTES.model(props.data.strapiCollections.name, name)),
          defaultImage: image,
          price: i18n.t("collection_model_price", { tubPrice }),
          filtersTypes: mappedFilters(therapies, [
            {
              installation_type_mapping:
                installationType.installationTypeMapping,
              name: installationType.name,
              title: installationType.installationTypeMapping.title,
              title_fr: installationType.installationTypeMapping.title_fr,
            },
          ]),
        };
      },
    );
  }, [collectionModels]);

  const filterFieldsList: Array<IFilterGroup> = [
    {
      title: i18n.t("collections_installation_type_filter"),
      filterType: FilterType.InstallationType,
      filters: filters.filter(
        filter => filter.filterType === FilterType.InstallationType,
      ),
    },
    {
      title: i18n.t("collections_therapy_filter"),
      filterType: FilterType.Therapy,
      filters: filters.filter(
        filter => filter.filterType === FilterType.Therapy,
      ),
    },
  ];

  const pageTitle = `${i18n.t("meta_title_prefix")} - ${name}`;

  return (
    <GeneralLayout breadcrumbs={breadcrumbs}>
      <SEO
        path={ROUTES.collection(name)}
        title={pageTitle}
        description={localizedDescription}
        imageUrl={!!image ? image.childImageSharp.fluid.src : undefined}
      />
      <CenterColumn>
        <Header
          titleDesktop={i18n.t("collection_title", { name })}
          titleMobile={name}
        />
      </CenterColumn>
      <div className={style.sideBySide}>
        <div className={style.sideBySideLeft}>
          <div className={style.sideBySideLeftHack}>
            <div className={style.sideBySideLeftHackRatio}>
              <div style={{ paddingTop: "60%" }}></div>
            </div>
            <div className={style.sideBySideLeftHackImage}>
              <div className={style.sideBySideImageWrapper}>
                {!!image?.childImageSharp?.fluid && (
                  <Img
                    className={style.sideBySideImage}
                    fluid={image.childImageSharp.fluid}
                    alt={name}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={style.sideBySideRight}>
          <div className={style.sideBySideRightContent}>
            <h3 className={style.sideBySideTitle}>{localizedTitle}</h3>
            <div className={style.sideBySideDescription}>
              <Markdown source={localizedDescription} />
            </div>
          </div>
        </div>
      </div>
      <div className={style.grid}>
        <div className={style.gridContent}>
          <h3 className={style.gridTitle}>
            {i18n.t("collection_models_title")}
          </h3>
          <FilteredGrid
            items={gridItems}
            filterGroups={filterFieldsList}
            btnText={i18n.t("collection_model_btn")}
          />
        </div>
      </div>
    </GeneralLayout>
  );
};

export default page(Collection);

export const query = graphql`
  query CollectionTemplate($id: Int) {
    allStrapiInstallationTypeMappings {
      nodes {
        id: strapiId
        name
        title
        title_fr
        type
        installationTypes: installation_types {
          id
        }
      }
    }
    allStrapiModels(
      sort: { fields: name, order: ASC }
      filter: { collection: { id: { eq: $id } } }
    ) {
      nodes {
        id
        name
        tubPrice
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        installationType: installation_type {
          name
          installationTypeMapping: installation_type_mapping
        }
        therapies {
          id
          name
        }
      }
    }
    strapiCollections(strapiId: { eq: $id }) {
      name
      models {
        id
        name
        tubPrice
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      installationTypes: installation_types {
        id
        name
        installation_type_mapping
      }
      therapies {
        id
        name
        category
      }
      title
      titleFr: title_fr
      description
      descriptionFr: description_fr
      image {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`;
